import axios from "axios";
import { handleApiError } from "services/utils/errorHandler";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_Base_URL}/payments`,
});

const config = (token: any) => {
  return {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
};

api.interceptors.response.use(null, handleApiError);

export const fetchAllPlans = () => {
  return api.get("/payment-products/");
};
export const createCheckoutSessionApi = (checkoutData: any, token: string) => {
  const formData = new FormData();
  for (const key in checkoutData) {
    formData.append(key, checkoutData[key]);
  }
  return api.post("/checkout/", formData, config(token));
};

export const playAgainCheckoutApi = (token: string) => {
  return api.get(`/play-again-checkout/`, config(token));
};

export const createCheckoutForContentCreatorApi = (checkoutData: any, token: string) => {
  const formData = new FormData();
  for (const key in checkoutData) {
    formData.append(key, checkoutData[key]);
  }
  return api.post("/checkout-cc-game/", formData, config(token));
};

export const applyDiscountCodeApi = (checkoutData: any, token: string) => {
  const formData = new FormData();
  for (const key in checkoutData) {
    formData.append(key, checkoutData[key]);
  }
  return api.post("/check-discount-code/", formData, config(token));
};
