import React from "react";

export interface TeammateInviteToastProps {
  message: string;
  onAccept: () => void;
  onReject: () => void;
}

const TeammateInviteToast: React.FC<TeammateInviteToastProps> = ({ message, onAccept, onReject }) => {
  return (
    <div>
      <p>{message}</p>
      <div className="flex justify-between items-center gap-3">
        <button onClick={onAccept} className="bg-green-500 py-1 w-1/2 rounded">
          Accept
        </button>
        <button onClick={onReject} className="bg-red-500 py-1 w-1/2 rounded">
          Reject
        </button>
      </div>
    </div>
  );
};

export default TeammateInviteToast;
