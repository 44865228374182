import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchContentCreatorGameListApi } from "services/apis/games";
import { RootState } from "store/rootReducer/rootReducer";

export const fetchContentCreatorGameList = createAsyncThunk(
  "games/fetchContentCreatorGameList",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state?.login?.user?.access;
      const { data } = await fetchContentCreatorGameListApi(token);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface ContentCreatorGameListState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  gameList: any[];
}

const initialState: ContentCreatorGameListState = {
  status: "idle",
  error: null,
  gameList: [],
};

const contentCreatorGameListSlice = createSlice({
  name: "contentCreatorGameList",
  initialState,
  reducers: {
    resetContentCreatorGameListStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentCreatorGameList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchContentCreatorGameList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.gameList = action.payload;
      })
      .addCase(fetchContentCreatorGameList.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetContentCreatorGameListStatus } = contentCreatorGameListSlice.actions;
export default contentCreatorGameListSlice.reducer;
