import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { acceptMatchActiveOrInactiveApi } from "services/apis/games";
import { RootState } from "store/rootReducer/rootReducer";

export const acceptMatchActiveOrInactive = createAsyncThunk(
  "matches/acceptMatchActiveOrInactive",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state?.login?.user?.access;
      const { data } = await acceptMatchActiveOrInactiveApi(token);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface AcceptMatchActiveOrInactiveState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  response: any;
}

const initialState: AcceptMatchActiveOrInactiveState = {
  status: "idle",
  error: null,
  response: null,
};

const acceptMatchActiveOrInactiveSlice = createSlice({
  name: "acceptMatchActiveOrInactive",
  initialState,
  reducers: {
    resetAcceptMatchActiveOrInactiveStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.response = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(acceptMatchActiveOrInactive.pending, (state) => {
        state.status = "loading";
      })
      .addCase(acceptMatchActiveOrInactive.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.response = action.payload;
      })
      .addCase(acceptMatchActiveOrInactive.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetAcceptMatchActiveOrInactiveStatus } = acceptMatchActiveOrInactiveSlice.actions;
export default acceptMatchActiveOrInactiveSlice.reducer;
