import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchChatPreferencesApi } from "services/apis/games";
import { RootState } from "store/rootReducer/rootReducer";

export const fetchChatPreferences = createAsyncThunk(
  "chatPreferences/fetchChatPreferences",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state?.login?.user?.access;
      const { data } = await fetchChatPreferencesApi(token);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

interface ChatPreferencesState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  preferences: any[];
}

const initialState: ChatPreferencesState = {
  status: "idle",
  error: null,
  preferences: [],
};

const chatPreferencesSlice = createSlice({
  name: "chatPreferences",
  initialState,
  reducers: {
    chatPreferencesResetStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatPreferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChatPreferences.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.preferences = action.payload;
      })
      .addCase(fetchChatPreferences.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { chatPreferencesResetStatus } = chatPreferencesSlice.actions;
export default chatPreferencesSlice.reducer;
