import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import PreLoader from "components/preloader/PreLoader";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={<PreLoader />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  // {/* </React.StrictMode> */}
);

reportWebVitals();
