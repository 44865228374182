import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMyMatchesApi } from "services/apis/games";
import { RootState } from "store/rootReducer/rootReducer";

export const getMyMatches = createAsyncThunk("matches/getMyMatches", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const { access: token, is_teammate } = state?.login?.user;
    const { data } = await getMyMatchesApi(token, is_teammate);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface MyMatchesState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  matches: any;
}

const initialState: MyMatchesState = {
  status: "idle",
  error: null,
  matches: [],
};

const myMatchesSlice = createSlice({
  name: "myMatches",
  initialState,
  reducers: {
    resetMyMatchesStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyMatches.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMyMatches.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.matches = action.payload;
      })
      .addCase(getMyMatches.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetMyMatchesStatus } = myMatchesSlice.actions;
export default myMatchesSlice.reducer;
