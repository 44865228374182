import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createChatRoomApi } from "services/apis/chats";
import { RootState } from "store/rootReducer/rootReducer";

export const createChatRoom = createAsyncThunk("chat/createChatRoom", async (chatRoomData: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state?.login?.user?.access;
    const { data } = await createChatRoomApi(chatRoomData, token);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface ChatRoomState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  chatRoom: any;
}

const initialState: ChatRoomState = {
  status: "idle",
  error: null,
  chatRoom: null,
};

const chatRoomSlice = createSlice({
  name: "chatRoom",
  initialState,
  reducers: {
    resetChatRoomStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createChatRoom.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createChatRoom.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chatRoom = action.payload;
      })
      .addCase(createChatRoom.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetChatRoomStatus } = chatRoomSlice.actions;
export default chatRoomSlice.reducer;
