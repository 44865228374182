const languageConfig = {
  en: {
    home: {
      home: {
        hero: {
          title: {
            text: "Join Forces.",
            highlighted: "Dominate Together.",
          },
          description:
            "Find the perfect teammates, conquer challenges, and level up your gaming experience like never before.",
          characters: ["K", "I", "T", "S", "U", "N", "E"],
        },
        availableNow: {
          text1: ["A", " V", "A", "I", "L", "A", "B", "L", "E"],
          text2: ["N", "O", "W"],
        },
        comingSoon: {
          text1: ["C", "O", "M", "I", "N", "G"],
          text2: ["S", "O", "O", "N"],
        },
        aboutUs: {
          text1: ["A", "B", "O", "U", "T"],
          text2: ["U", "S"],
          title: "About Kitsune.",
          subTitle: "We know searching for good teammates is hard.",
          content: {
            text1:
              "With Kitusne you can find the best teammate to play with, we picked out the best players from every game to level your gaming experience to the next level. ",
            text2:
              "Playing with kitsune isn’t about increasing your rank, It's an all-encompassing, sophisticated gaming experience. You'll be actively participating and learning fresh tactics and viewpoints from more experienced gamers.",
            text3:
              "Our priority is to make sure your account is safe. That's why we provide teammates to play alongside without taking your account which will make your gaming experience much better and enjoyable.",
          },
        },
        weOffering: {
          text1: ["W", "E"],
          text2: ["O", "F", "F", "E", "R", "I", "N", "G"],
          title: "We Offering.",
          cards: [
            {
              imgSrc: require("assets/icons/exp.png"),
              title: "New experience",
              description: "Playing competitive games and having fun together ! We made the impossible.",
            },
            {
              imgSrc: require("assets/icons/support.png"),
              title: "Support services",
              description: "Support agents at your service 24/7, but the website is perfect you dont need this.",
            },
            {
              imgSrc: require("assets/icons/time.png"),
              title: "No wasted time",
              description: "Our teammates are always on standby waiting for you.",
            },
            {
              imgSrc: require("assets/icons/alphaPlayer.png"),
              title: "Creating an alpha player",
              description: "Come on Guys!! We have the best players to wake up the monster inside you.",
            },
            {
              imgSrc: require("assets/icons/safety.png"),
              title: "100% Safety Rate",
              description: "Our teammates don't know how to use cheats or hacks.",
            },
            {
              imgSrc: require("assets/icons/community.png"),
              title: "Better Community",
              description: "Healthy vibes , supportive community , 0% toxicity",
            },
          ],
        },
        working: {
          text1: ["W", "O", "R", "K", "I", "N", "G"],
          title: "How it works?",
          cards: [
            { number: "01", name: "Setup your account.", desc: "Sign up now and be part of us." },
            {
              number: "02",
              name: "Choose a Game & Mode",
              desc: "The game mode determines what type of teammate we match with you.",
            },
            {
              number: "03",
              name: "Start",
              desc: "You will be matched with your perfect teammate in less than 3 min.",
            },
          ],
        },
      },
      contentCreator: {},
      selectMode: {
        title: "Select Mode",
        quantity: "QUantity",
        findMatch: "FIND MATCH",
        selected: "SELECTED",
        choose: {
          title: "Choose",
          input1: { label: "Rank", placeholder: "Add a Rank" },
          input2: { label: "Role", placeholder: "Add a Role" },
          input3: {
            label: "Platform",
            dropdown: {
              label1: "PlayStation",
              label2: "Computer",
            },
          },
        },
        applyDiscountCode: {
          title: "Apply Discount Code",
          placeholder: "Add a Discount Code",
          applyBtn: "APPLY",
        },
      },
    },
  },
  ar: {
    home: {
      home: {
        hero: {
          title: {
            text: " وصلت خير",
            highlighted: "بتطور مستواك.",
          },
          description: "ادخل مع افضل اللاعبين، تعلم استراتيجياتهم ، واستمتع بتجربة جديدة تغير نظرتك عن الالعاب",
          characters: ["ك", "ي", "ت", "س", "و", "ن", "ي"],
        },
        availableNow: {
          text1: ["م", "ت", "و", "ف", "ر"],
          text2: ["ا", "ل", "ا", "ن"],
        },
        comingSoon: {
          text1: ["ق", "ا", "د", "م"],
          text2: ["ق", "ر", "ي", "ب", "ا"],
        },
        aboutUs: {
          text1: ["ك", "ي", "ت", "س", "و", "ن", "ي"],
          text2: ["ع", "ن"],
          title: "عن كيتسوني",
          subTitle: "نعرف صعوبة البحث عن لاعب ممتاز بفريقك.",
          content: {
            text1:
              "مع كيتسوني، تقدر تلقى أفضل فريق تلعب معه، اخترنا أفضل اللاعبين من كل لعبة عشان ينقلوا تجربة الألعاب التنافسية لمستوى آخر.",
            text2:
              "هدف كيتسوني مو رفع رانكك، هدفنا تجربة لعب شاملة ومتطورة. بتشارك بحماس وتتعلم تكتيكات ووجهات نظر جديدة من لاعبين أكثر خبرة.",
            text3:
              "أولويتنا نتأكد من أمان حسابك. ولهذا السبب وفرنا محترفين تلعب معهم بدون مايطلبون حسابك، هذا الي بيخلي تجربتك افضل وممتعه اكثر من اي مكان اخر.",
          },
        },
        weOffering: {
          text1: ["ن", "ح", "ن"],
          text2: ["ن", "ق", "د", "م"],
          title: "نحن نقدم",
          cards: [
            {
              imgSrc: require("assets/icons/exp.png"),
              title: "تجربه جديده",
              description: "تلعب كومب وتستمتع بنفس الوقت! صنعنا المستحيل",
            },
            {
              imgSrc: require("assets/icons/support.png"),
              title: "خدمة الدعم",
              description: "فريق دعم في خدمتك 24/7 بس ما تحتاجه موقعنا مافيه ولا غلطه",
            },
            {
              imgSrc: require("assets/icons/time.png"),
              title: "مافي وقت ضايع",
              description: "موظفينا دايم على اهبة الاستعداد بانتظارك طلبك",
            },
            {
              imgSrc: require("assets/icons/alphaPlayer.png"),
              title: "نصنع قيقا تشاد",
              description: "صدقني اخترنا أفضل اللاعبين وبمقدرتهم يطلعوا الوحش الذي بداخلك",
            },
            {
              imgSrc: require("assets/icons/safety.png"),
              title: "نسبة امان 100%",
              description: "لاعبين كيتسوني مايعرفون يغشون او يستخدمون اي هاك",
            },
            {
              imgSrc: require("assets/icons/community.png"),
              title: "مجتمع افضل",
              description: "أجواء صحية, مجتمع داعم, 0% قلة حياء",
            },
          ],
        },
        working: {
          text1: ["ا", "ل", "ك", "ي", "ف", "ي", "ه"],
          title: "كيف يشتغل؟",
          cards: [
            { number: "01", name: "ضبط حسابك", desc: "سجل الان وسوي لك حساب معنا" },
            {
              number: "02",
              name: "حدد اللعبة والخدمة",
              desc: "اختيارك للخدمه يحدد نوع اللاعب الي بنوفره لك",
            },
            {
              number: "03",
              name: "ابدأ",
              desc: "نظامنا بيسوي لك اتصال مع اللاعب المثالي في اقل من 3 دقائق",
            },
          ],
        },
      },
      contentCreator: {},
      selectMode: {
        title: "حدد الخدمه",
        quantity: "العدد",
        findMatch: "ابدا البحث",
        selected: "مختارة",
        choose: {
          title: "اختيار",
          input1: { label: "رانكك", placeholder: "أضف رتبة" },
          input2: { label: "رولك", placeholder: "أضف دورًا" },
          input3: {
            label: "المنصه",
            dropdown: {
              label1: "كونسل ",
              label2: "بي سي",
            },
          },
        },
        applyDiscountCode: {
          title: "كود الخصم",
          placeholder: "ضع كود الخصم",
          applyBtn: "تطبيق",
        },
      },
    },
  },
};

export default languageConfig;
