import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SessionState {
  isEnded: boolean;
  matchId: string | null;
}

const initialState: SessionState = {
  isEnded: false,
  matchId: null,
};

export const isSessionEndedSlice = createSlice({
  name: "isSessionEnded",
  initialState,
  reducers: {
    setIsSessionEnded: (state, action: PayloadAction<boolean>) => {
      state.isEnded = action.payload;
    },
    setMatchId: (state, action: PayloadAction<string>) => {
      state.matchId = action.payload;
    },
    resetSessionState: (state) => {
      state.isEnded = false;
      state.matchId = null;
    },
  },
});

export const { setIsSessionEnded, setMatchId, resetSessionState } = isSessionEndedSlice.actions;

export default isSessionEndedSlice.reducer;
