import { combineReducers } from "redux";
import loginSlice from "../slices/auth/loginSlice";
import signupSlice from "store/slices/auth/signupSlice";
import verifyUserSlice from "store/slices/auth/verifyUserSlice";
import getAllPlansSlice from "store/slices/payments/getAllPlansSlice";
import checkoutSlice from "store/slices/payments/checkoutSlice";
import playPreferencesSlice from "store/slices/games/playPreferencesSlice";
import chatPreferencesSlice from "store/slices/games/chatPreferencesSlice";
import lastMatchRequestSlice from "store/slices/games/lastMatchRequestSlice";
import matchingSlice from "store/slices/matchingProcess/matchingSlice";
import queueTeammatesSlice from "store/slices/matchingProcess/QueueTeammates";
import matchSlice from "store/slices/games/matchSlice";
import myMatchesSlice from "store/slices/games/myMatchesSlice";
import chatRoomSlice from "store/slices/chats/createChatRoomSlice";
import specificRoomChatSlice from "store/slices/chats/specificRoomChatSlice";
import gamesListSlice from "store/slices/games/gamesListSlice";
import clientDetailsSlice from "store/slices/auth/clientDetailsSlice";
import teammateDetailsSlice from "store/slices/games/teammateDetailsSlice";
import userDetailsSlice from "store/slices/auth/userDetailsSlice";
import changePasswordSlice from "store/slices/auth/changePasswordSlice";
import updateProfileImageSlice from "store/slices/auth/updateProfileImageSlice";
import teammateProfileSlice from "store/slices/games/teammateProfileSlice";
import acceptMatchActiveOrInactiveSlice from "store/slices/games/acceptMatchActiveOrInactiveSlice";
import endMatchSlice from "store/slices/games/endMatchSlice";
import matchTimeSlice from "store/slices/games/matchTimeSlice";
import isSessionEndedSlice from "store/slices/matchingProcess/isSessionEndedSlice";
import rateTeammateSlice from "store/slices/games/rateTeammateSlice";
import alreadyPaidSlice from "store/slices/games/alreadyPaidSlice";
import playAgainCheckoutSlice from "store/slices/payments/playAgainCheckoutSlice";
import wantToPlayAgainSlice from "store/slices/games/wantToPlayAgainSlice";
import earningStatsSlice from "store/slices/games/earningStatsSlice";
import teammateEarningsSlice from "store/slices/games/teammateEarningsSlice";
import cancelMatchRequestSlice from "store/slices/games/cancelMatchRequestSlice";
import contentCreatorGameListSlice from "store/slices/games/contentCreatorGameListSlice";
import createCheckoutForContentCreatorSlice from "store/slices/payments/createCheckoutForContentCreatorSlice";
import applyDiscountCodeSlice from "store/slices/payments/applyDiscountCodeSlice";
import languageSlice from "store/slices/languageSlice";

const rootReducer = combineReducers({
  // Accounts
  login: loginSlice,
  signup: signupSlice,
  verifyUser: verifyUserSlice,
  clientDetails: clientDetailsSlice,
  userDetails: userDetailsSlice,
  changePassword: changePasswordSlice,
  updateProfileImage: updateProfileImageSlice,
  // Payments
  allPlans: getAllPlansSlice,
  checkout: checkoutSlice,
  playAgainCheckout: playAgainCheckoutSlice,
  createCheckoutForContentCreator: createCheckoutForContentCreatorSlice,
  applyDiscountCode: applyDiscountCodeSlice,
  // games
  playPreferences: playPreferencesSlice,
  chatPreferences: chatPreferencesSlice,
  lastMatchRequest: lastMatchRequestSlice,
  match: matchSlice,
  myMatches: myMatchesSlice,
  gamesList: gamesListSlice,
  teammateDetails: teammateDetailsSlice,
  teammateProfile: teammateProfileSlice,
  acceptMatchActiveOrInactive: acceptMatchActiveOrInactiveSlice,
  endMatch: endMatchSlice,
  matchTime: matchTimeSlice,
  rateTeammate: rateTeammateSlice,
  alreadyPaid: alreadyPaidSlice,
  wantToPlayAgain: wantToPlayAgainSlice,
  earningStats: earningStatsSlice,
  teammateEarnings: teammateEarningsSlice,
  cancelMatchRequest: cancelMatchRequestSlice,
  contentCreatorGameList: contentCreatorGameListSlice,
  // matching
  matching: matchingSlice,
  queueTeammates: queueTeammatesSlice,
  isSessionEnded: isSessionEndedSlice,
  // chats
  createChatRoom: chatRoomSlice,
  specificRoomChat: specificRoomChatSlice,
  // Language
  language: languageSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
