import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createCheckoutSessionApi } from "services/apis/payments";

export const createCheckoutSession = createAsyncThunk(
  "checkout/createCheckoutSession",
  async ({ checkoutData, token }: any, thunkAPI) => {
    try {
      const { data } = await createCheckoutSessionApi(checkoutData, token);
      // if (data) {
      //   const newWindow = window.open(data?.order?.url);
      //   newWindow!.opener = null;
      // }
      // console.log("data::: ", data);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface CheckoutState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  session: any;
}

const initialState: CheckoutState = {
  status: "idle",
  error: null,
  session: null,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCheckoutSession.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.session = action.payload;
      })
      .addCase(createCheckoutSession.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetStatus } = checkoutSlice.actions;
export default checkoutSlice.reducer;
