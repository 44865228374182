import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchGamesListApi } from "services/apis/games";

export const fetchGamesList = createAsyncThunk("games/fetchGamesList", async (_, thunkAPI) => {
  try {
    const { data } = await fetchGamesListApi();
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface GamesListState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  games: any;
}

const initialState: GamesListState = {
  status: "idle",
  error: null,
  games: [],
};

const gamesListSlice = createSlice({
  name: "gamesList",
  initialState,
  reducers: {
    resetGamesListStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGamesList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGamesList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.games = action.payload;
      })
      .addCase(fetchGamesList.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetGamesListStatus } = gamesListSlice.actions;
export default gamesListSlice.reducer;
