import React from "react";
import "./preloader.scss";
const PreLoader = () => {
  return (
    <div className="h-[100vh] w-full flex justify-center items-center relative">
      <img src={require("assets/images/bgBubble.png")} alt="" className="absolute left-0 top-0 w-full h-full" />
      <div className="col-md-2 loader5 bg-new-blue">
        <div className="breeding-rhombus-spinner">
          <div className="rhombus child-1"></div>
          <div className="rhombus child-2"></div>
          <div className="rhombus child-3"></div>
          <div className="rhombus child-4"></div>
          <div className="rhombus child-5"></div>
          <div className="rhombus child-6"></div>
          <div className="rhombus child-7"></div>
          <div className="rhombus child-8"></div>
          <div className="rhombus big"></div>
        </div>
      </div>
    </div>
  );
};

export default PreLoader;
