import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchLastMatchRequestApi } from "services/apis/games";
import { RootState } from "store/rootReducer/rootReducer";

export const fetchLastMatchRequest = createAsyncThunk("lastMatchRequest/fetchLastMatchRequest", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state?.login?.user?.access;
    const { data } = await fetchLastMatchRequestApi(token);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface LastMatchRequestState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  lastMatchRequest: any;
}

const initialState: LastMatchRequestState = {
  status: "idle",
  error: null,
  lastMatchRequest: null,
};

const lastMatchRequestSlice = createSlice({
  name: "lastMatchRequest",
  initialState,
  reducers: {
    lastMatchRequestSliceResetStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLastMatchRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLastMatchRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.lastMatchRequest = action.payload;
      })
      .addCase(fetchLastMatchRequest.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { lastMatchRequestSliceResetStatus } = lastMatchRequestSlice.actions;
export default lastMatchRequestSlice.reducer;
