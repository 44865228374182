import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAllPlans } from "services/apis/payments";

export const getAllPlans = createAsyncThunk("plans/getAllPlans", async (_, thunkAPI) => {
  try {
    const { data } = await fetchAllPlans();
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface AllPlansState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  allPlans: any[];
}

const initialState: AllPlansState = {
  status: "idle",
  error: null,
  allPlans: [],
};

const allPlansSlice = createSlice({
  name: "allPlans",
  initialState,
  reducers: {
    resetAllPlansStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlans.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllPlans.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.allPlans = action.payload;
      })
      .addCase(getAllPlans.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetAllPlansStatus } = allPlansSlice.actions;
export default allPlansSlice.reducer;
