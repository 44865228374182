import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { playAgainCheckoutApi } from "services/apis/payments";
import { RootState } from "store/rootReducer/rootReducer";

export const playAgainCheckout = createAsyncThunk("playAgainCheckout/playAgainCheckout", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state?.login?.user?.access;
    const { data } = await playAgainCheckoutApi(token);
    if (data) {
      const newWindow = window.open(data?.order?.url);
      newWindow!.opener = null;
    }
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface PlayAgainCheckoutState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  checkoutInfo: any;
}

const initialState: PlayAgainCheckoutState = {
  status: "idle",
  error: null,
  checkoutInfo: null,
};

const playAgainCheckoutSlice = createSlice({
  name: "playAgainCheckout",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.checkoutInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(playAgainCheckout.pending, (state) => {
        state.status = "loading";
      })
      .addCase(playAgainCheckout.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.checkoutInfo = action.payload;
      })
      .addCase(playAgainCheckout.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetStatus } = playAgainCheckoutSlice.actions;
export default playAgainCheckoutSlice.reducer;
