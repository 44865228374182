import React from "react";

const TeammateWaiting = ({ message, show, onClose }: any) => {
  return (
    <div
      className={`waiting-toast ${
        show ? "show" : ""
      } bg-[rgba(26,26,33,1)] rounded-[21px] border border-[rgba(98,96,118,0.33)] max-w-[507px] w-full px-5`}
    >
      <div className="flex justify-between items-center gap-4 h-[76px]">
        <div className="flex justify-start items-center gap-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="w-16 h-16 mx-[-12px]"
            style={{ shapeRendering: "auto", display: "block" }}
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g>
              <g transform="rotate(0 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="-0.9166666666666666s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(30 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="-0.8333333333333334s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(60 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="-0.75s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(90 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="-0.6666666666666666s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(120 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="-0.5833333333333334s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(150 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="-0.5s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(180 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="-0.4166666666666667s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(210 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="-0.3333333333333333s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(240 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="-0.25s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(270 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="-0.16666666666666666s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(300 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="-0.08333333333333333s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g transform="rotate(330 50 50)">
                <rect fill="#61c2e1" height="12" width="6" ry="6" rx="3" y="24" x="47">
                  <animate
                    repeatCount="indefinite"
                    begin="0s"
                    dur="1s"
                    keyTimes="0;1"
                    values="1;0"
                    attributeName="opacity"
                  ></animate>
                </rect>
              </g>
              <g></g>
            </g>
          </svg>
          <h6 className="font-poppins font-normal text-base text-white">{message}</h6>
        </div>
        <button className="font-poppins font-normal text-base text-white" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default TeammateWaiting;
