import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPlayPreferencesApi } from "services/apis/games";
import { RootState } from "store/rootReducer/rootReducer";

export const fetchPlayPreferences = createAsyncThunk("playPreferences/fetchPlayPreferences", async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state?.login?.user?.access;
    const { data } = await fetchPlayPreferencesApi(token);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface PlayPreferencesState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  preferences: any[];
}

const initialState: PlayPreferencesState = {
  status: "idle",
  error: null,
  preferences: [],
};

const playPreferencesSlice = createSlice({
  name: "playPreferences",
  initialState,
  reducers: {
    playPreferencesResetStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayPreferences.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPlayPreferences.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.preferences = action.payload;
      })
      .addCase(fetchPlayPreferences.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { playPreferencesResetStatus } = playPreferencesSlice.actions;
export default playPreferencesSlice.reducer;
