import { AxiosError } from "axios";
import { toast } from "react-toastify";

export const handleApiError = (error: AxiosError): Promise<never> => {
  const defaultErrorMessage = "An unexpected error occurred";
  let errorMessage = defaultErrorMessage;

  if (error.response && error.response.data) {
    const responseData = error.response.data as { detail?: string; non_field_errors?: string[]; message?: string };

    if (responseData.detail) {
      if (Array.isArray(responseData.detail)) {
        errorMessage = responseData.detail[0];
      } else {
        errorMessage = responseData.detail;
      }
    } else if (Array.isArray(responseData.non_field_errors)) {
      errorMessage = responseData.non_field_errors[0];
    } else if (typeof error.response.data === "string") {
      errorMessage = error.response.data;
    } else if (typeof responseData.message === "string") {
      errorMessage = responseData.message;
    }
  }

  toast.error(errorMessage, {
    position: "top-right",
    autoClose: 30000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  return Promise.reject(error);
};
