import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { changePasswordApi } from "services/apis/auth";
import { RootState } from "store/rootReducer/rootReducer";

export const changePassword = createAsyncThunk("auth/changePassword", async (passwordData: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const { access: token } = state?.login?.user;
    const { data } = await changePasswordApi(token, passwordData);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface ChangePasswordState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  response: any;
}

const initialState: ChangePasswordState = {
  status: "idle",
  error: null,
  response: null,
};

const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    resetChangePasswordStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.response = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.response = action.payload;
      })
      .addCase(changePassword.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetChangePasswordStatus } = changePasswordSlice.actions;
export default changePasswordSlice.reducer;
