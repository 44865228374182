import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { endMatchApi } from "services/apis/games";
import { RootState } from "store/rootReducer/rootReducer";

export const endMatch = createAsyncThunk("matches/endMatch", async (matchData: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state?.login?.user?.access;
    const { data } = await endMatchApi(token, matchData);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface EndMatchState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  response: any; // Adjust the type according to your response data structure
}

const initialState: EndMatchState = {
  status: "idle",
  error: null,
  response: null,
};

const endMatchSlice = createSlice({
  name: "endMatch",
  initialState,
  reducers: {
    resetEndMatchStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.response = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(endMatch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(endMatch.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.response = action.payload;
      })
      .addCase(endMatch.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetEndMatchStatus } = endMatchSlice.actions;
export default endMatchSlice.reducer;
