// import { useEffect, useRef, useState } from "react";
// import { ToastOptions, toast } from "react-toastify";
// import TeammateInviteToast, { TeammateInviteToastProps } from "components/toasts/TeammateInvite";
// import notificationSoundUrl from "assets/audios/KatakuriHakiSoundOnePiece.mp3";
// import { useAppDispatch } from "./hooks";
// import { addTeammate } from "store/slices/matchingProcess/QueueTeammates";
// import { setIsSessionEnded, setMatchId } from "store/slices/matchingProcess/isSessionEndedSlice";
// import { playAgainCheckout } from "store/slices/payments/playAgainCheckoutSlice";

// interface MessageData {
//   message_type: string;
//   teammate_data?: any;
//   client_data?: {
//     id: number;
//     username: string;
//   };
//   match_data?: any;
//   chat_room?: any;
//   match?: any;
//   match_request?: any;
// }

// const notificationToastOptions: ToastOptions<unknown> = {
//   position: "top-right",
//   autoClose: 30000,
//   hideProgressBar: false,
//   closeOnClick: true,
//   pauseOnHover: true,
//   draggable: true,
//   progress: undefined,
//   theme: "dark",
// };

// const playNotificationSound = () => {
//   const audio = new Audio(notificationSoundUrl);
//   audio.play().catch((error) => console.error("Audio play error:", error));
// };

// const showToastNotification = (message: string, options?: ToastOptions<unknown>) => {
//   toast.info(message, { ...notificationToastOptions, ...options });
// };

// const createTeammateInviteProps = (
//   message: string,
//   onAccept: () => void,
//   onReject: () => void
// ): TeammateInviteToastProps => ({
//   message,
//   onAccept,
//   onReject,
// });

// export default function useWebSocket(userId: number) {
//   const dispatch = useAppDispatch();
//   const socket = useRef<WebSocket | null>(null);
//   const [showWaitingToast, setShowWaitingToast] = useState(false);
//   useEffect(() => {
//     const webSocketEndpoint = `wss://chat-dot-sodium-elf-417418.de.r.appspot.com/ws/communication?user_id=${userId}`;

//     socket.current = new WebSocket(webSocketEndpoint);

//     socket.current.onmessage = function (e: MessageEvent) {
//       const messageData: MessageData = JSON.parse(e.data);
//       if (messageData) {
//         const audio = new Audio(notificationSoundUrl);
//         let resp = audio.play();
//         if (resp !== undefined) {
//           resp
//             .then((_) => {
//               // autoplay starts!
//             })
//             .catch((error) => {
//               console.error("error::: ", error);
//             });
//         }
//       }
//       if (messageData.message_type === "teammate_accepted") {
//         const teammateData = messageData.teammate_data;
//         toast.info(`Your teammate ${teammateData.name} has accepted!`, {
//           position: "top-right",
//           autoClose: 30000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//         dispatch(addTeammate(teammateData));
//       } else if (messageData.message_type === "teammate_rejected") {
//         const teammateData = messageData.teammate_data;
//         toast.info(`Your teammate ${teammateData.name} has rejected!`, {
//           position: "top-right",
//           autoClose: 30000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//       } else if (messageData.message_type === "chat_room") {
//         const toastProps: ToastOptions<unknown> = {
//           position: "top-right",
//           autoClose: 30000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         };
//         const handleAccept = () => {
//           window.location.href = `/chat/${messageData?.match_data.client.id}/${messageData.chat_room}`;
//         };
//         const teammateInviteProps: TeammateInviteToastProps = {
//           message: `Client has accepted your request, chat now!!!`,
//           onAccept: handleAccept,
//           onReject: () => {},
//         };

//         toast.info(<TeammateInviteToast {...teammateInviteProps} />, toastProps);
//       } else if (messageData.message_type === "session_started") {
//         toast.info(`Your teammate ${messageData.match?.client?.username} has started the session!`, {
//           position: "top-right",
//           autoClose: 30000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//         const currentUrl = new URL(window.location.href);
//         currentUrl.searchParams.set("session_started", "true");
//         window.history.pushState({}, "", currentUrl);
//         window.location.reload();
//       } else if (messageData.message_type === "session_ended") {
//         toast.info(`Your teammate ${messageData.match?.team_mates[0]?.name} has started the session!`, {
//           position: "top-right",
//           autoClose: 30000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//         dispatch(setIsSessionEnded(true));
//         dispatch(setMatchId(messageData.match.id));
//       } else if (messageData.message_type === "want_to_play_again") {
//         const handleAccept = () => {
//           const acceptMessage = {
//             message_type: "teammate_play_again_accepted",
//             teammate_id: userId,
//             user_id: messageData.match_request.client.id,
//           };
//           if (socket.current) {
//             socket.current.send(JSON.stringify(acceptMessage));
//           }
//         };

//         const handleReject = () => {
//           const rejectMessage = {
//             message_type: "teammate_play_again_rejected",
//             teammate_id: userId,
//             user_id: messageData.match_request.client.id,
//           };
//           if (socket.current) {
//             socket.current.send(JSON.stringify(rejectMessage));
//           }
//         };

//         const toastProps: ToastOptions<unknown> = {
//           position: "top-right",
//           autoClose: 30000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         };

//         const teammateInviteProps: TeammateInviteToastProps = {
//           message: `'${messageData.match_request.client?.username}' wants to play again!`,
//           onAccept: handleAccept,
//           onReject: handleReject,
//         };

//         toast.info(<TeammateInviteToast {...teammateInviteProps} />, toastProps);
//       } else if (messageData.message_type === "teammate_play_again_accepted") {
//         dispatch(playAgainCheckout());
//       } else if (messageData.message_type === "teammate_play_again_rejected") {
//         toast.error(`Your teammate '${messageData.teammate_data.name}' has rejected to play again!`, {
//           position: "top-right",
//           autoClose: 30000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//       } else if (messageData.message_type === "client_ignored") {
//         toast.error(`Client has matched with someone else!`, {
//           position: "top-right",
//           autoClose: 30000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//         setShowWaitingToast(false);
//       } else if (messageData.message_type === "client_canceled") {
//         toast.error(`Client has cancelled!`, {
//           position: "top-right",
//           autoClose: 30000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         });
//         window.location.href = "/";
//       } else {
//         const handleAccept = () => {
//           const acceptMessage = {
//             message_type: "teammate_accepted",
//             teammate_id: userId,
//             user_id: messageData.client_data?.id,
//           };
//           if (socket.current) {
//             socket.current.send(JSON.stringify(acceptMessage));
//           }
//           setShowWaitingToast(true);
//         };

//         const handleReject = () => {
//           const rejectMessage = {
//             message_type: "teammate_rejected",
//             teammate_id: userId,
//             user_id: messageData.client_data?.id,
//           };
//           if (socket.current) {
//             socket.current.send(JSON.stringify(rejectMessage));
//           }
//         };

//         const toastProps: ToastOptions<unknown> = {
//           position: "top-right",
//           autoClose: 30000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "dark",
//         };

//         const teammateInviteProps: TeammateInviteToastProps = {
//           message: `We have matched you with a teammate (${messageData.client_data?.username}) and the game mode is ${messageData?.match_request?.payment_product?.name}!`,
//           onAccept: handleAccept,
//           onReject: handleReject,
//         };

//         toast.info(<TeammateInviteToast {...teammateInviteProps} />, toastProps);
//       }
//     };

//     socket.current.onopen = (e) => {
//       // console.log("WebSocket open:", e);
//     };
//     socket.current.onerror = (e) => {
//       // console.log("WebSocket error:", e);
//     };
//     socket.current.onclose = (e) => {
//       // console.log("WebSocket closed:", e);
//     };

//     return () => {
//       if (socket.current && socket.current.readyState === 1) {
//         socket.current.close();
//       }
//     };
//   }, [userId]);

//   return { showWaitingToast, setShowWaitingToast };
// }
import { useEffect, useRef, useState, useCallback } from "react";
import { ToastOptions, toast } from "react-toastify";
import TeammateInviteToast, { TeammateInviteToastProps } from "components/toasts/TeammateInvite";
import notificationSoundUrl from "assets/audios/KatakuriHakiSoundOnePiece.mp3";
import { useAppDispatch } from "./hooks";
import { addTeammate } from "store/slices/matchingProcess/QueueTeammates";
import { setIsSessionEnded, setMatchId } from "store/slices/matchingProcess/isSessionEndedSlice";
import { playAgainCheckout } from "store/slices/payments/playAgainCheckoutSlice";

interface MessageData {
  message_type: string;
  teammate_data?: any;
  client_data?: {
    id: number;
    username: string;
  };
  match_data?: any;
  chat_room?: any;
  match?: any;
  match_request?: any;
}

const notificationToastOptions: ToastOptions<unknown> = {
  position: "top-right",
  autoClose: 30000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

const playNotificationSound = () => {
  const audio = new Audio(notificationSoundUrl);
  audio.play().catch((error) => console.error("Audio play error:", error));
};

const showToastNotification = (message: string, options?: ToastOptions<unknown>) => {
  toast.info(message, { ...notificationToastOptions, ...options });
};

const showDesktopNotification = (title: string, body: string) => {
  if (document.visibilityState !== "visible" && Notification.permission === "granted") {
    const notification = new Notification(title, { body });
    notification.onclick = () => {
      window.focus();
    };
  }
};

const createTeammateInviteProps = (
  message: string,
  onAccept: () => void,
  onReject: () => void
): TeammateInviteToastProps => ({
  message,
  onAccept,
  onReject,
});

export default function useWebSocket(userId: number) {
  const dispatch = useAppDispatch();
  const socket = useRef<WebSocket | null>(null);
  const [showWaitingToast, setShowWaitingToast] = useState(false);

  const handleTeammateAccepted = useCallback(
    (messageData: MessageData) => {
      const { name } = messageData.teammate_data;
      const message = `Your teammate ${name} has accepted!`;
      showToastNotification(message);
      showDesktopNotification("Teammate Accepted", message);
      dispatch(addTeammate(messageData.teammate_data));
    },
    [dispatch]
  );

  const handleTeammateRejected = useCallback((messageData: MessageData) => {
    const { name } = messageData.teammate_data;
    const message = `Your teammate ${name} has rejected!`;
    showToastNotification(message);
    showDesktopNotification("Teammate Rejected", message);
  }, []);

  const handleChatRoom = useCallback((messageData: MessageData) => {
    const handleAccept = () => {
      window.location.href = `/chat/${messageData.match_data.client.id}/${messageData.chat_room}`;
    };
    const toastProps = createTeammateInviteProps(
      "Client has accepted your request, chat now!!!",
      handleAccept,
      () => {}
    );
    const message = "Client has accepted your request, chat now!!!";
    toast.info(<TeammateInviteToast {...toastProps} />, notificationToastOptions);
    showDesktopNotification("Chat Room", message);
  }, []);

  const handleSessionStarted = useCallback((messageData: MessageData) => {
    const message = `Your teammate ${messageData.match?.client?.username} has started the session!`;
    showToastNotification(message);
    showDesktopNotification("Session Started", message);
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("session_started", "true");
    window.history.pushState({}, "", currentUrl);
    window.location.reload();
  }, []);

  const handleSessionEnded = useCallback(
    (messageData: MessageData) => {
      const message = `Your teammate ${messageData.match?.team_mates[0]?.name} has ended the session!`;
      showToastNotification(message);
      showDesktopNotification("Session Ended", message);
      dispatch(setIsSessionEnded(true));
      dispatch(setMatchId(messageData.match.id));
    },
    [dispatch]
  );

  const handleWantToPlayAgain = useCallback(
    (messageData: MessageData) => {
      const handleAccept = () => {
        socket.current?.send(
          JSON.stringify({
            message_type: "teammate_play_again_accepted",
            teammate_id: userId,
            user_id: messageData.match_request.client.id,
          })
        );
      };

      const handleReject = () => {
        socket.current?.send(
          JSON.stringify({
            message_type: "teammate_play_again_rejected",
            teammate_id: userId,
            user_id: messageData.match_request.client.id,
          })
        );
      };

      const toastProps = createTeammateInviteProps(
        `'${messageData.match_request.client?.username}' wants to play again!`,
        handleAccept,
        handleReject
      );
      const message = `'${messageData.match_request.client?.username}' wants to play again!`;
      toast.info(<TeammateInviteToast {...toastProps} />, notificationToastOptions);
      showDesktopNotification("Want to Play Again", message);
    },
    [userId]
  );

  const handleTeammatePlayAgainAccepted = useCallback(() => {
    dispatch(playAgainCheckout());
  }, [dispatch]);

  const handleTeammatePlayAgainRejected = useCallback((messageData: MessageData) => {
    const message = `Your teammate '${messageData.teammate_data.name}' has rejected to play again!`;
    showToastNotification(message);
    showDesktopNotification("Play Again Rejected", message);
  }, []);

  const handleClientIgnored = useCallback(() => {
    const message = "Client has matched with someone else!";
    showToastNotification(message);
    showDesktopNotification("Client Ignored", message);
    setShowWaitingToast(false);
  }, []);

  const handleClientCanceled = useCallback(() => {
    const message = "Client has cancelled!";
    showToastNotification(message);
    showDesktopNotification("Client Canceled", message);
    window.location.href = "/";
  }, []);

  const handleDefault = useCallback(
    (messageData: MessageData) => {
      const handleAccept = () => {
        socket.current?.send(
          JSON.stringify({
            message_type: "teammate_accepted",
            teammate_id: userId,
            user_id: messageData.client_data?.id,
          })
        );
        setShowWaitingToast(true);
      };

      const handleReject = () => {
        socket.current?.send(
          JSON.stringify({
            message_type: "teammate_rejected",
            teammate_id: userId,
            user_id: messageData.client_data?.id,
          })
        );
      };

      const toastProps = createTeammateInviteProps(
        `We have matched you with a teammate (${messageData.client_data?.username}) and the game mode is ${messageData?.match_request?.payment_product?.name}!`,
        handleAccept,
        handleReject
      );
      const message = `We have matched you with a teammate (${messageData.client_data?.username}) and the game mode is ${messageData?.match_request?.payment_product?.name}!`;
      toast.info(<TeammateInviteToast {...toastProps} />, notificationToastOptions);
      showDesktopNotification("New Teammate Matched", message);
    },
    [userId]
  );

  useEffect(() => {
    const webSocketEndpoint = `wss://chat-dot-sodium-elf-417418.de.r.appspot.com/ws/communication?user_id=${userId}`;
    socket.current = new WebSocket(webSocketEndpoint);

    socket.current.onmessage = (e: MessageEvent) => {
      const messageData: MessageData = JSON.parse(e.data);
      if (!messageData) return;

      playNotificationSound();

      switch (messageData.message_type) {
        case "teammate_accepted":
          handleTeammateAccepted(messageData);
          break;
        case "teammate_rejected":
          handleTeammateRejected(messageData);
          break;
        case "chat_room":
          handleChatRoom(messageData);
          break;
        case "session_started":
          handleSessionStarted(messageData);
          break;
        case "session_ended":
          handleSessionEnded(messageData);
          break;
        case "want_to_play_again":
          handleWantToPlayAgain(messageData);
          break;
        case "teammate_play_again_accepted":
          handleTeammatePlayAgainAccepted();
          break;
        case "teammate_play_again_rejected":
          handleTeammatePlayAgainRejected(messageData);
          break;
        case "client_ignored":
          handleClientIgnored();
          break;
        case "client_canceled":
          handleClientCanceled();
          break;
        default:
          handleDefault(messageData);
          break;
      }
    };

    socket.current.onopen = () => {
      // WebSocket connection opened
    };

    socket.current.onerror = (e) => {
      console.error("WebSocket error:", e);
    };

    socket.current.onclose = (e) => {
      // WebSocket connection closed
    };

    return () => {
      if (socket.current && socket.current.readyState === WebSocket.OPEN) {
        socket.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return { showWaitingToast, setShowWaitingToast };
}
