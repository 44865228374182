import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PreLoader from "components/preloader/PreLoader";

const Login = lazy(() => import("../pages/Login"));
const Signup = lazy(() => import("../pages/Signup"));
const VerifyUser = lazy(() => import("../pages/VerifyUser"));
const Home = lazy(() => import("../pages/Home"));
// const Smoke = lazy(() => import("components/smoke/Smoke"));
const Performance = lazy(() => import("../pages/Performance"));
const Earnings = lazy(() => import("../pages/Earnings"));
const HelpAndSupport = lazy(() => import("../pages/HelpAndSupport"));
const SearchTeammate = lazy(() => import("../pages/SearchTeammate"));
const Chat = lazy(() => import("../pages/Chat"));
const RouteComp = () => {
  return (
    <Router>
      <Suspense fallback={<PreLoader />}>
        <Routes>
          {/* <Route path="/" element={<Navigate to={`/login`} replace state={"/"} />}></Route> */}
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/verify-user" element={<VerifyUser />}></Route>
          <Route path="/performance" element={<Performance />}></Route>
          <Route path="/earnings" element={<Earnings />}></Route>
          <Route path="/help-and-Support" element={<HelpAndSupport />}></Route>
          <Route path="/search-teammate" element={<SearchTeammate />}></Route>
          <Route path="/chat/:rId/:chatRoomId" element={<Chat />}></Route>
        </Routes>
      </Suspense>
      <Suspense fallback={<PreLoader />}>
      {/* <Smoke /> */}
      </Suspense>
    </Router>
  );
};

export default RouteComp;
