import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer/rootReducer";
import createFilter from "redux-persist-transform-filter";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { logout } from "./slices/auth/loginSlice";
const devTools =
  process.env.NODE_ENV === "development"
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    : undefined;

const loginFilter = createFilter("login", ["isLoggedIn", "user"]);
const persistConfig = {
  key: "kitsune",
  storage,
  whitelist: ["login", "language"],
  transforms: [loginFilter],
};

const persistedReducer = persistReducer(persistConfig, rootReducer as any);
const logoutMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type.endsWith("/rejected")) {
    const error = action.payload;
    if (error && error.code === "token_not_valid") {
      store.dispatch(logout());
      window.location.href = "/login";
    }
  }
  // const { dispatch } = store;
  // if (action.error && action.payload.message && action.payload.status === 401) {
  //   dispatch(logout());
  //   window.location.href = "/login";
  // }

  return next(action);
};
const store = configureStore({
  reducer: persistedReducer,
  devTools,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logoutMiddleware),
});

const persistor = persistStore(store);
export { store, persistor };
export type AppDispatch = typeof store.dispatch;
