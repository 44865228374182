import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateProfileImageApi } from "services/apis/auth";
import { RootState } from "store/rootReducer/rootReducer";

export const updateProfileImage = createAsyncThunk("profile/updateProfileImage", async (imageData: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const { access: token } = state?.login?.user;
    const formData = new FormData();
    formData.append("profile_image", imageData);
    const { data } = await updateProfileImageApi(token, formData);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface UpdateProfileImageState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  response: any;
}

const initialState: UpdateProfileImageState = {
  status: "idle",
  error: null,
  response: null,
};

const updateProfileImageSlice = createSlice({
  name: "updateProfileImage",
  initialState,
  reducers: {
    resetUpdateProfileImageStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.response = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfileImage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfileImage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.response = action.payload;
      })
      .addCase(updateProfileImage.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetUpdateProfileImageStatus } = updateProfileImageSlice.actions;
export default updateProfileImageSlice.reducer;
