import React from "react";
import "./assets/styles/globals.scss";
import Routes from "routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector } from "services/hooks/hooks";
import useWebSocket from "services/hooks/useWebSocket";
import TeammateWaiting from "components/toasts/TeammateWaiting";
import useTranslations from "services/utils/useTranslations";

function App() {
  const userId = useAppSelector((state) => state.login?.user?.id);
  const { showWaitingToast, setShowWaitingToast } = useWebSocket(userId);
  const { lang } = useTranslations();
  return (
    <div className="App" style={{ direction: lang === "en" ? "ltr" : "rtl" }}>
      <div className="h-full overflow-auto">
        <TeammateWaiting
          message="Waiting For teammate Response..."
          show={showWaitingToast}
          onClose={() => setShowWaitingToast(false)}
        />
        <ToastContainer
          position="top-right"
          autoClose={30000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          limit={1}
        />

        <Routes />
      </div>
    </div>
  );
}

export default App;
