import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchSpecificRoomChatApi } from "services/apis/chats";
import { RootState } from "store/rootReducer/rootReducer";

export const fetchSpecificRoomChat = createAsyncThunk("chat/fetchSpecificRoomChat", async (roomId: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state?.login?.user?.access;
    const { data } = await fetchSpecificRoomChatApi(roomId, token);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface SpecificRoomChatState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  messages: any[];
}

const initialState: SpecificRoomChatState = {
  status: "idle",
  error: null,
  messages: [],
};

const specificRoomChatSlice = createSlice({
  name: "specificRoomChat",
  initialState,
  reducers: {
    resetSpecificRoomStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpecificRoomChat.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSpecificRoomChat.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.messages = action.payload;
      })
      .addCase(fetchSpecificRoomChat.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetSpecificRoomStatus } = specificRoomChatSlice.actions;
export default specificRoomChatSlice.reducer;
