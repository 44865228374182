import axios from "axios";
import { handleApiError } from "services/utils/errorHandler";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_Base_URL}/accounts`,
});

const config = (token: any) => {
  return {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
};

api.interceptors.response.use(null, handleApiError);

export const signup = (formData: any) => api.post("/register/", formData);

export const login = (formData: any) => api.post("/login/", formData);

export const verifyCode = (formData: any) => api.post("/verify-code/", formData);

export const fetchClientDetailsApi = (token: string, clientId: any) => {
  return api.get(`/client-detail/${clientId}/`, config(token));
};

export const fetchUserDetailsApi = (token: string) => {
  return api.get(`/client-detail/`, config(token));
};

export const changePasswordApi = (token: string, passwordData: any) => {
  const formData = new FormData();
  for (const key in passwordData) {
    formData.append(key, passwordData[key]);
  }
  return api.post(`/change-password/`, formData, config(token));
};

export const updateProfileImageApi = (token: string, image: any) => {
  return api.post(`/user-info/`, image, config(token));
};
