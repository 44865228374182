import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { rateTeammateApi } from "services/apis/games";
import { RootState } from "store/rootReducer/rootReducer";

export const rateTeammate = createAsyncThunk("rateTeammate/rateTeammate", async (details: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state?.login?.user?.access;
    const { data } = await rateTeammateApi(token, details);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface RateTeammateState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  response: any;
}

const initialState: RateTeammateState = {
  status: "idle",
  error: null,
  response: null,
};

const rateTeammateSlice = createSlice({
  name: "rateTeammate",
  initialState,
  reducers: {
    resetRateTeammateStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.response = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(rateTeammate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(rateTeammate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.response = action.payload;
      })
      .addCase(rateTeammate.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetRateTeammateStatus } = rateTeammateSlice.actions;
export default rateTeammateSlice.reducer;
