import axios from "axios";
import { handleApiError } from "services/utils/errorHandler";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_Base_URL}/chat`,
});

const config = (token: any) => {
  return {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
};

api.interceptors.response.use(null, handleApiError);


export const createChatRoomApi = (chatRoomData: any, token: string) => {
  return api.get(`/create-chat-room/?sid=${chatRoomData.sid}&rid=${chatRoomData.rid}`, config(token));
};

export const fetchSpecificRoomChatApi = (roomId: any, token: string) => {
  return api.get(`/chat-messages?chat_room_id=${roomId}`, config(token));
};
