import { createSlice } from "@reduxjs/toolkit";

interface LanguageState {
  language: "en" | "ar";
}

const initialState: LanguageState = {
  language: "ar",
};

const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    resetLanguage: (state) => {
      state.language = "ar";
    },
  },
});

export const { setLanguage, resetLanguage } = languageSlice.actions;
export default languageSlice.reducer;
