import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchClientDetailsApi } from "services/apis/auth";
import { RootState } from "store/rootReducer/rootReducer";

export const fetchClientDetails = createAsyncThunk("clients/fetchClientDetails", async (clientId: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const { access: token } = state?.login?.user;
    const { data } = await fetchClientDetailsApi(token, clientId);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface ClientDetailsState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  clientDetails: any;
}

const initialState: ClientDetailsState = {
  status: "idle",
  error: null,
  clientDetails: null,
};

const clientDetailsSlice = createSlice({
  name: "clientDetails",
  initialState,
  reducers: {
    resetClientDetailsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchClientDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clientDetails = action.payload;
      })
      .addCase(fetchClientDetails.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetClientDetailsStatus } = clientDetailsSlice.actions;
export default clientDetailsSlice.reducer;
