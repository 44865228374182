import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createCheckoutForContentCreatorApi } from "services/apis/payments";
import { RootState } from "store/rootReducer/rootReducer";

export const createCheckoutForContentCreator = createAsyncThunk(
  "checkout/createCheckoutForContentCreator",
  async (checkoutData: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state?.login?.user?.access;
      const { data } = await createCheckoutForContentCreatorApi(checkoutData, token);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface CreateCheckoutForContentCreatorState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  checkout: any;
}

const initialState: CreateCheckoutForContentCreatorState = {
  status: "idle",
  error: null,
  checkout: null,
};

const createCheckoutForContentCreatorSlice = createSlice({
  name: "createCheckoutForContentCreator",
  initialState,
  reducers: {
    resetCreateCheckoutForContentCreatorStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCheckoutForContentCreator.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCheckoutForContentCreator.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.checkout = action.payload;
      })
      .addCase(createCheckoutForContentCreator.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetCreateCheckoutForContentCreatorStatus } = createCheckoutForContentCreatorSlice.actions;
export default createCheckoutForContentCreatorSlice.reducer;
