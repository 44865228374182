import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { applyDiscountCodeApi } from "services/apis/payments";
import { RootState } from "store/rootReducer/rootReducer";

export const applyDiscountCode = createAsyncThunk("checkout/applyDiscountCode", async (discountData: any, thunkAPI) => {
  try {
    const state = thunkAPI.getState() as RootState;
    const token = state?.login?.user?.access;
    const { data } = await applyDiscountCodeApi(discountData, token);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface ApplyDiscountCodeState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  discount: any;
}

const initialState: ApplyDiscountCodeState = {
  status: "idle",
  error: null,
  discount: null,
};

const applyDiscountCodeSlice = createSlice({
  name: "applyDiscountCode",
  initialState,
  reducers: {
    resetApplyDiscountCodeStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applyDiscountCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(applyDiscountCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.discount = action.payload;
      })
      .addCase(applyDiscountCode.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetApplyDiscountCodeStatus } = applyDiscountCodeSlice.actions;
export default applyDiscountCodeSlice.reducer;
