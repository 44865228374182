import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchTeammateDetailsApi } from "services/apis/games";
import { RootState } from "store/rootReducer/rootReducer";

export const fetchTeammateDetails = createAsyncThunk(
  "teammates/fetchTeammateDetails",
  async (teammateId: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const { access: token } = state?.login?.user;
      const { data } = await fetchTeammateDetailsApi(token, teammateId);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface TeammateDetailsState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  teammateDetails: any;
}

const initialState: TeammateDetailsState = {
  status: "idle",
  error: null,
  teammateDetails: null,
};

const teammateDetailsSlice = createSlice({
  name: "teammateDetails",
  initialState,
  reducers: {
    resetTeammateDetailsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeammateDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTeammateDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.teammateDetails = action.payload;
      })
      .addCase(fetchTeammateDetails.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetTeammateDetailsStatus } = teammateDetailsSlice.actions;
export default teammateDetailsSlice.reducer;
