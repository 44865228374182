import axios from "axios";
import { handleApiError } from "services/utils/errorHandler";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_Base_URL}/matching`,
});

const config = (token: any) => {
  return {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
};

api.interceptors.response.use(null, handleApiError);

export const makeMatchingApi = (token: string, matchData: any) => {
  const formData = new FormData();
  for (const key in matchData) {
    formData.append(key, matchData[key]);
  }
  return api.post("/match-request/", formData, config(token));
};
