import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { checkIfAlreadyPaidApi } from "services/apis/games";
import { RootState } from "store/rootReducer/rootReducer";

export const checkIfAlreadyPaid = createAsyncThunk(
  "payments/checkIfAlreadyPaid",
  async (checkoutData: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const token = state?.login?.user?.access;
      const { data } = await checkIfAlreadyPaidApi(token, checkoutData);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface AlreadyPaidState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  hasPaid: boolean;
}

const initialState: AlreadyPaidState = {
  status: "idle",
  error: null,
  hasPaid: false,
};

const alreadyPaidSlice = createSlice({
  name: "alreadyPaid",
  initialState,
  reducers: {
    resetAlreadyPaidStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.hasPaid = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkIfAlreadyPaid.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkIfAlreadyPaid.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hasPaid = action.payload;
      })
      .addCase(checkIfAlreadyPaid.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetAlreadyPaidStatus } = alreadyPaidSlice.actions;
export default alreadyPaidSlice.reducer;
