import axios from "axios";
import { handleApiError } from "services/utils/errorHandler";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_Base_URL}/games`,
});

const config = (token: any) => {
  return {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
};

api.interceptors.response.use(null, handleApiError);

export const fetchGamesListApi = () => {
  return api.get("/list/");
};

export const fetchPlayPreferencesApi = (token: string) => {
  return api.get("/play-preferences/", config(token));
};

export const fetchChatPreferencesApi = (token: string) => {
  return api.get("/chat-preferences/", config(token));
};

export const fetchLastMatchRequestApi = (token: string) => {
  return api.get("/last-match-request/", config(token));
};

export const createMatchApi = (matchData: string, token: string) => {
  const formData = new FormData();
  // formData.append("teammates_selected", matchData);
  formData.append("client_id", matchData);
  return api.post("/create-match/", formData, config(token));
};

export const getMyMatchesApi = (token: string, is_teammate: boolean) => {
  const endpoint = is_teammate ? `/matches/` : `/matches/?for_client=true`;
  return api.get(endpoint, config(token));
};

export const fetchTeammateDetailsApi = (token: string, teammateId: any) => {
  return api.get(`/teammate-detail/${teammateId}`, config(token));
};

export const fetchTeammateProfileApi = (token: string) => {
  return api.get(`/teammate-profile/`, config(token));
};

export const acceptMatchActiveOrInactiveApi = (token: string) => {
  return api.get(`/teammate-active-or-inactive/`, config(token));
};

export const endMatchApi = (token: string, matchData: any) => {
  const formData = new FormData();
  for (const key in matchData) {
    formData.append(key, matchData[key]);
  }
  return api.post(`/end-match/`, formData, config(token));
};

export const getMatchTimeApi = (token: string, matchId: any) => {
  return api.get(`/match-time?match_id=${matchId}`, config(token));
};

export const rateTeammateApi = (token: string, details: any) => {
  const formData = new FormData();
  for (const key in details) {
    formData.append(key, details[key]);
  }
  return api.post(`/rate-teammate/`, formData, config(token));
};

export const checkIfAlreadyPaidApi = (token: string, checkoutData: any) => {
  const formData = new FormData();
  for (const key in checkoutData) {
    formData.append(key, checkoutData[key]);
  }
  return api.post(`/paid-request/`, formData, config(token));
};

export const wantToPlayAgainApi = (token: string) => {
  return api.get(`/want-play-again/`, config(token));
};

export const fetchEarningStatsApi = (token: string) => {
  return api.get(`/earning-stats/`, config(token));
};

export const fetchTeammateEarningsApi = (token: string) => {
  return api.get(`/teammate-earning/`, config(token));
};

export const cancelMatchRequestApi = (match_req_id: any, token: string) => {
  return api.get(`/cancel-match-request?match_req_id=${match_req_id}`, config(token));
};
export const fetchContentCreatorGameListApi = (token: string) => {
  return api.get(`/content-games/`);
};
