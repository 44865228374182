import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { verifyCode } from "services/apis/auth";

export const verifyUser = createAsyncThunk("auth/verifyUser", async (signupData: any, thunkAPI) => {
  try {
    const formData = new FormData();
    for (const key in signupData) {
      formData.append(key, signupData[key]);
    }
    const { data } = await verifyCode(formData);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface signupState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
  code: any;
}

const initialState: signupState = {
  status: "idle",
  error: null,
  code: null,
};
const verifyUserSlice = createSlice({
  name: "verifyUser",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.code = action.payload;
      })
      .addCase(verifyUser.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetStatus } = verifyUserSlice.actions;
export default verifyUserSlice.reducer;
