import languageConfig from "languageConfig";
import { useAppSelector } from "services/hooks/hooks";

const useTranslations = () => {
  const L = useAppSelector((state) => state.language.language);
  const T: any = languageConfig[L];
  const translate = (key: string) => {
    const keys = key.split(".");
    let result = T;
    for (const k of keys) {
      result = result[k];
      if (result === undefined) {
        return key;
      }
    }
    return result || key;
  };
  return { translate, lang: L };
};

export default useTranslations;
