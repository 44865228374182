import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface QueueTeammatesState {
  teammates: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: any;
}

const initialState: QueueTeammatesState = {
  teammates: [],
  status: "idle",
  error: null,
};

export const queueTeammatesSlice = createSlice({
  name: "queueTeammates",
  initialState,
  reducers: {
    addTeammate: (state, action: PayloadAction<any>) => {
      state.teammates.push(action.payload);
    },
    clearTeammates: (state) => {
      state.teammates = [];
    },
    setQueueStatus: (state, action: PayloadAction<"idle" | "loading" | "succeeded" | "failed">) => {
      state.status = action.payload;
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
  },
});

export const { addTeammate, clearTeammates, setQueueStatus, setError } = queueTeammatesSlice.actions;

export default queueTeammatesSlice.reducer;
